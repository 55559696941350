import {SET_DEALERS} from './action';
const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_DEALERS: {
    return action.payload;
  }
  default: {
    return state;
  }
  }
}
