export default {
  CHANGE_LOCATION_DISTANCE_SELECTED: 'CHANGE_LOCATION_DISTANCE_SELECTED',
  CHANGE_LOCATION_ZIP_CODE_SELECTED: 'CHANGE_LOCATION_ZIP_CODE_SELECTED',
  ZIP_CODE_FETCH_PENDING: 'ZIP_CODE_FETCH_PENDING',
  ZIP_CODE_FETCH_FULFILLED: 'ZIP_CODE_FETCH_FULFILLED',
  ZIP_CODE_FETCH_REJECTED: 'ZIP_CODE_FETCH_REJECTED',
  CHANGE_IS_CAN_AUTO_EXTEND_DISTANCE: 'CHANGE_IS_CAN_AUTO_EXTEND_DISTANCE',
  CHANGE_IS_CHANGED_DISTANCE: 'CHANGE_IS_CHANGED_DISTANCE',
  CHANGE_IS_CHANGED_ZIP_CODE: 'CHANGE_IS_CHANGED_ZIP_CODE',
  CHANGE_LOCATION_ZIP_CODE_PREVIOUS_SELECTED: 'CHANGE_LOCATION_ZIP_CODE_PREVIOUS_SELECTED',
  CHANGE_IS_DISTANCE_DEFAULT_BE_SET: 'CHANGE_IS_DISTANCE_DEFAULT_BE_SET',
};
