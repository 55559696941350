import {SET_ESTIMATE} from './actions';

export const estimate = (state = null, action) => {
  switch (action.type) {
  case SET_ESTIMATE: {
    return action.payload;
  }

  default: {
    return state;
  }
  }
};
