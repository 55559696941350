import types from './types';

const initialState = {
  isChanged: false,
  value: [],
};

export const updateCategorySelectedReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_CATEGORY_SELECTED: {
    if (state.value.includes(action.payload)) {
      return {
        ...state,
        value: state.value.filter(cate => cate !== action.payload),
      };
    }
    return {
      ...state,
      value: [...state.value, action.payload],
    };
  }
  case types.SET_CATEGORIES_SELECTED: {
    return {
      ...state,
      value: action.payload,
    };
  }
  case types.CHANGE_IS_CHANGED_CATEGORIES: {
    return {
      ...state,
      isChanged: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
