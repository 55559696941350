import {
  COLOR_SEARCH_PARAM_KEY,
  DEALER_TYPE_SEARCH_PARAM_KEY,
  DEALER_SEARCH_PARAM_KEY,
  FAMILY_SEARCH_PARAM_KEY,
  MILES_RADIUS_SEARCH_PARAM_KEY,
  MODEL_SEARCH_PARAM_KEY,
  PRICE_SEARCH_PARAM_KEY,
  SEGMENT_SEARCH_PARAM_KEY,
  SORT_BY_SEARCH_PARAM_KEY,
  ZIP_SEARCH_PARAM_KEY,
  MODEL_ID_SEARCH_PARAM_KEY,
  CATEGORY_SEARCH_PARAM_KEY,
  TRIMS_SEARCH_PARAM_KEY,
  COLORS_SEARCH_PARAM_KEY,
  YEAR_SEARCH_PARAM_KEY,
  VIN_STATUS_SEARCH_PARAM_KEY,
} from '../constants';

/**
 * valid Zip Code
 * @param {String} val
 * @return {Bool}
 */
export const validZipCode = val => {
  const regEx = /(^\d{5}$)/;
  return regEx.test(val);
};

/**
 * convert number to number with commas
 * @param {Number} num
 * @return {String}
 */
export const numberWithCommas = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Updates the browser history and pushes to new state
 *
 * @param {String} newQueryString - New url for the browser
 */
export const updateHistoryWithParams = newQueryString => {
  const newURLOrigin = `${location.origin}${location.pathname}`;
  let newUrl = newURLOrigin;
  if (newQueryString) {
    newUrl = `${newURLOrigin}?${newQueryString}`;
  }

  history.replaceState({}, '', newUrl);
  const linkCanonical = document.head.querySelector('link[rel="canonical"]');
  if (linkCanonical) {
    linkCanonical.href = encodeURI(decodeURIComponent(newUrl));
  }
};

/**
 * convert from array to object by group item by key
 * @param {Array} array
 * @param {String} key
 * @return {Object}
 */
export const groupArrayToObjectByKey = (array, key) => {
  if (!Array.isArray(array)) {
    return null;
  }

  const result = {};

  for (const item of array) {
    const keyGroup = item[key];
    const existingGroup = result[keyGroup] || [];
    result[keyGroup] = [...existingGroup, item];
  }

  return result;
};

/**
 * slugify
 * @param {String} text
 * @return {String}
 */
export const slugify = text => {
  if (text) {
    return text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/-+/g, '-')
      .replace(/[^\w-]+/g, '');
  }

  return '';
};

const easeOutCubic = x => {
  return 1 - Math.pow(1 - x, 2);
};
/**
 *  smooth scroll
 *  @param {HTMLElement} $element
 * @param {Number} duration
 * @param {Number} ajustSpace
 * @param {Function} callbackFn
 * @return {void}
 * */
export const smoothScroll = ($element, duration, ajustSpace = 0, callbackFn = null) => {
  const startingY = window.pageYOffset;
  let elementY = $element ? window.pageYOffset + $element.getBoundingClientRect().top : 0;
  let start;

  const step = timestamp => {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);

    elementY = window.pageYOffset + $element.getBoundingClientRect().top;
    const diff = elementY - startingY + ajustSpace;
    window.scrollTo(0, startingY + diff * easeOutCubic(percent));
    if (time < duration) {
      window.requestAnimationFrame(step);
    } else if (typeof callbackFn === 'function') {
      callbackFn();
    }
  };
  if ($element) {
    window.requestAnimationFrame(step);
  }
};

/**
 * test if element is descendant of parent
 * @param {HTMLElement} child
 * @param {HTMLElement} parent
 * @return {Boolean}
 * */
export const isDescendant = (child, parent) => {
  let node = child;
  while (node.parentNode) {
    if (node.parentNode === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

/**
 * set equal height for items
 * @param {Array} items
 * @return {void}
 * */
export const setEqualHeight = (items = []) => {
  const height = Math.max(...items.map(ref => ref?.offsetHeight ?? 0));
  items.forEach(item => {
    item.style.setProperty('height', `${height}px`);
  });
};

/**
 * get offset top of element
 * @param {HTMLElement} element
 * @return {Number}
 * */
export const getOffsetTop = element => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

/**
 * get element same offset top
 * @param {Array} items
 * @return {Array}
 **/
export const getElementSameOffsetTop = (items = []) => {
  const result = items.reduce((acc, item) => {
    const offsetTopItem = getOffsetTop(item);
    const exist = Object.keys(acc).indexOf(offsetTopItem.toString()) > -1;
    if (exist) {
      acc[offsetTopItem].push(item);
    } else {
      acc[offsetTopItem] = [item];
    }
    return acc;
  }, {});
  return Object.values(result);
};

/**
 * build url
 * @param {String} baseUrl
 * @param {Object} params
 * @param {Array} paramsOrder
 * @return {String}
 */
export const buildUrl = (
  baseUrl,
  params,
  paramsOrder = [
    SEGMENT_SEARCH_PARAM_KEY,
    CATEGORY_SEARCH_PARAM_KEY,
    FAMILY_SEARCH_PARAM_KEY,
    ZIP_SEARCH_PARAM_KEY,
    MILES_RADIUS_SEARCH_PARAM_KEY,
    YEAR_SEARCH_PARAM_KEY,
    MODEL_SEARCH_PARAM_KEY,
    MODEL_ID_SEARCH_PARAM_KEY,
    TRIMS_SEARCH_PARAM_KEY,
    COLOR_SEARCH_PARAM_KEY,
    COLORS_SEARCH_PARAM_KEY,
    PRICE_SEARCH_PARAM_KEY,
    DEALER_SEARCH_PARAM_KEY,
    SORT_BY_SEARCH_PARAM_KEY,
    DEALER_TYPE_SEARCH_PARAM_KEY,
    VIN_STATUS_SEARCH_PARAM_KEY,
  ]
) => {
  let urlParam = paramsOrder
    .reduce((stringParams, key) => params[key] ? [...stringParams, `${key}=${params[key]}`] : stringParams, [])
    .join('&');

  // hold diff params in search url
  const currentSearchParam = new window.URLSearchParams(window.location.search);
  const currentSearchParamKeys = Array.from(currentSearchParam.keys());
  currentSearchParamKeys.forEach(key => {
    if (!paramsOrder.includes(key)) {
      urlParam += `&${key}=${currentSearchParam.get(key)}`;
    }
  });

  return [baseUrl, urlParam].filter(Boolean).join('?');
};

/**
 * get current search params
 * @param {Array} keys
 * @return {Object}
 */
export const getCurrentSearchParams = keys => {
  const currentParams = new window.URLSearchParams(toLowerCaseSafe(location.search));
  return keys.reduce((acc, key) => {
    const value = currentParams.get(key);
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

/**
 * return value or null with condition
 * @param {*} condition
 * @param {*} value
 * @return {*}
 */
export const returnValueOrNullWithCondition = (condition, value) => {
  return condition ? value : null;
};

/**
 * to lower case safe
 * @param {String} text
 * @return {String}
 */
export const toLowerCaseSafe = text => {
  if (text) {
    return text.toLowerCase();
  }

  return text;
};

/**
 * convert float to currency
 * @param {Number} input
 * @return {String}
 */
export const floatToCurrency = input => {
  return input.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0});
};
