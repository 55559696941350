import {SET_COLOR} from './actions';
// const trim = JSON.parse( window.localStorage.getItem('trim') );

const initialState = 'blue';

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_COLOR: {
    return action.payload;
  }
  default: {
    return state;
  }
  }
}
