export default {
  MODEL_HIERARCHY_FETCH_PENDING: 'MODEL_HIERARCHY_FETCH_PENDING',
  MODEL_HIERARCHY_FETCH_FULFILLED: 'MODEL_HIERARCHY_FETCH_FULFILLED',
  MODEL_HIERARCHY_FETCH_REJECTED: 'MODEL_HIERARCHY_FETCH_REJECTED',
  MODEL_INVENTORY_FETCH_PENDING: 'MODEL_INVENTORY_FETCH_PENDING',
  MODEL_INVENTORY_FETCH_FULFILLED: 'MODEL_INVENTORY_FETCH_FULFILLED',
  MODEL_INVENTORY_FETCH_REJECTED: 'MODEL_INVENTORY_FETCH_REJECTED',
  MODEL_FILTER_RESULT: 'MODEL_FILTER_RESULT',
  CHANGE_BACK_TO_DEFAULT_SEARCH_PARAMS: 'CHANGE_BACK_TO_DEFAULT_SEARCH_PARAMS',
};
