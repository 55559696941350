
import tlite from '../plugins/tlite';
import Tooltip from 'tooltip.js';
import {msrpTemplate} from '../helpers';

export const createTooltips = ( item = [] ) =>
  item.map( elem =>
    tlite(el => $(el).hasClass(elem)));

export const createMsrpTooltips = (selectors = []) => {
  selectors.map( selector => {
    $(selector).each((index, element) => {
      initToolTip(element);
      tlite.show(element);
    });
  });
};

export const initToolTip = element => {
  $(element).keypress(e => {
    if (e.key === 'Enter') {
      $(e.currentTarget).click();
    }
  });

  return new Tooltip($(element), {
    title: $(element).data('disclaimer'),
    trigger: 'click',
    html: true,
    closeOnClickOutside: true,
    placement: 'bottom-start',
    template: msrpTemplate,
    container: $(element).data('disclaimer-container') ? document.getElementsByClassName($(element).data('disclaimer-container'))[0] : false,
    popperOptions: {
      onCreate: data => {
        const lastFocusedEl = document.activeElement;
        $(data.instance.popper).find('.tooltip-close').on('click', e => {
          e.preventDefault();
          lastFocusedEl.focus();
          $(element).click();
        });
      },
    },
  });
};


