import create from '@verndale/core';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import './plugins/bg-lazyload';
import {renderReactModule} from './helpers';
import {createTooltips, createMsrpTooltips} from './components/globals';
import {dmtTrackWithDataLayer} from './helpers';
import _throttle from 'lodash/throttle';

// create modules
const modules = [
  {
    name: 'HeroMosaic',
    props: {
      speed: 400,
      autoplaySpeed: 5000,
    },
  },
  {name: 'specs/SpecsInterior'},
  {name: 'features/FeaturesInterior'},
  {name: 'Features'},
  {name: 'Specs'},
  {name: 'Publications'},
  {name: 'Events'},
  {name: 'ModelGallery'},
  {name: 'ColorSwatchGroup'},
  {name: 'TrimLanding'},
  {name: 'PageNav'},
  {name: 'Header'},
  {name: 'MapModule'},
  {name: 'Social'},
  {name: 'faq/Faq'},
  {name: 'faq/FaqNav'},
  {name: 'AddToCalendar'},
  {name: 'Chart'},
  {name: 'DisclaimerModule'},
  {name: 'CoverageDrawer'},
  {name: 'GearModule'},
  {name: 'Hero'},
  {name: 'HeroAcessories'},
  {name: 'Intro'},
  {name: 'OffersModule'},
  {name: 'SegmentGallery'},
  {name: 'PlanDetails'},
  {name: 'FeaturedModels'},
  {name: 'CharacterTruncate'},
  {name: 'CoursesListing'},
  {name: 'FeaturedRacing'},
  {name: 'FooterSignup'},
  {name: 'VideoModal'},
  {name: 'Footer'},
  {name: 'LeavingPopup'},
  {name: 'CheckboxGroup'},
  {name: 'BecomeDealerForm'},
  {name: 'TalonForm'},
  {name: 'UnsuscribeSurvey'},
  {name: 'become-dealer/BecomeDealer'},
  {name: 'become-dealer/SelectState'},
  {name: 'CompareModels'},
  {name: 'CompareModelsTimsList'},
  {name: 'WithComparisonTool'},
  {name: 'Preferences'},
  {name: 'Marketing'},
  {name: 'Resources'},
  {name: 'MillenialHero'},
  {name: 'HomepageParallax'},
  {name: 'help-me-choose/HMCOverview'},
  {name: 'recalls/RecallsHeader'},
  {name: 'FinancialServices'},
  {name: 'FamilyLayout'},
  {name: 'SlideOutToolTip'},
  {name: 'generic-dct/GenericDctHero'},
  {name: 'RiderEducationCourses'},
  {
    name: 'accessories/AccessoriesListing',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'accessories/AccessoriesNoFilter',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'accessories/Accessories',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'dealer-finder/DealerFinder',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'wallpapers/Wallpapers',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'build-and-price/BPRoot',
    render(Root, el) {
      const React = require('react');
      const {render} = require('react-dom');
      const store = require('./modules/build-and-price/store').default;
      render(
        <Root
          store={store}
          url={el[0].dataset.url}
          useConfigurator={el[0].dataset.useConfigurator}
          shareUrl={el[0].dataset.shareUrl}
        />,
        el[0]
      );
    },
  },
  {name: 'raq/RAQModal'},
  {
    name: 'raq/RAQRoot',
    render(Root, el) {
      const React = require('react');
      const {render} = require('react-dom');
      const store = require('./modules/raq/store').default;

      render(<Root store={store} />, el[0]);
    },
  },
  {
    name: 'budget-calculator/BudgetCalculatorRoot',
    render(Root, el) {
      const React = require('react');
      const {render} = require('react-dom');
      const store = require('./modules/budget-calculator/store').default;

      render(<Root store={store} />, el[0]);
    },
  },
  {
    name: 'financial-calculator/FinancialCalculatorRoot',
    render(Root, el) {
      const React = require('react');
      const {render} = require('react-dom');
      const store = require('./modules/financial-calculator/store').default;

      render(<Root store={store} />, el[0]);
    },
  },
  {
    name: 'inventory/InventoryRoot',
    render(Root, el) {
      const React = require('react');
      const {render} = require('react-dom');
      const store = require('./modules/inventory/store').default;

      render(<Root store={store} rootElement={el[0]} />, el[0]);
    },
  },
  {
    name: 'Offers',
    render(Offers, el) {
      const React = require('react');
      const {render} = require('react-dom');
      render(<Offers {...el[0].dataset} />, el[0]);
    },
  },
  {
    name: 'downloads/Downloads',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'recalls/Recalls',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'search-results/SearchResults',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'events-listing/EventsListing',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'bp-landing/BPLanding',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'CurrentOffers',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'demo-rides/DemoRides',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'CurrentOffersDetail',
    render(Offers, el) {
      const React = require('react');
      const {render} = require('react-dom');
      render(<Offers {...el[0].dataset} />, el[0]);
    },
  },
  {
    name: 'all-models/Banner',
    props: {
      speed: 400,
      autoplaySpeed: 5000,
    },
  },
  {
    name: 'atv-listing/AtvListing',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'trims-compare-modal/TCMRoot',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'compare-models-trim-overlay/CMTORoot',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {
    name: 'help-me-choose/tool/HMCRoot',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
  {name: 'NewAltImageContent'},
  {name: 'RiderEducationSegment'},
  {name: 'Carplay'},
  {name: 'resources/ResourcesSelectState'},
  {name: 'CoursesResult'},
  {name: 'resources/ResourcesNavigation'},
  {name: 'resources/ResourcesTestimonials'},
  {name: 'resources/ResourcesBannerModule'},
  {name: 'resources/ResourcesEventCalendar'},
  {name: 'dct-informational/DCTMenu'},
  {name: 'dct-informational/DCTHeader'},
  {name: 'dct-informational/DCTWhatIt'},
  {name: 'dct-informational/DCTBenefitsInfo'},
  {name: 'dct-informational/DCTHowItWorks'},
  {name: 'dct-informational/DCTTestimonials'},
  {name: 'dct-informational/DCTBenefitsBreak'},
  {name: 'dct-informational/DCTTrims'},
  {name: 'dct-informational/DCTBenefitsCarousel'},
  {name: 'dct-informational/DCTModal'},
  {name: 'dct-informational/DCTAdvantages'},
  {name: 'n2k/n2kHero'},
  {name: 'n2k/n2kOverview'},
  {name: 'n2k/n2kIntersitial'},
  {name: 'n2k/n2kModels'},
  {name: 'hero-dct/HeroDCT'},
  {name: 'grom-graphics-kit-addition/GromGraphicsKitAddition'},
  {name: 'model-slider/ModelSlider'},
  {name: 'grom-graphics-kit-addition-fender-2023/GromGraphicsKitAdditionFender'},
  {
    name: 'dealer-finder-dct/DealerFinderDCT',
    render(component, el) {
      renderReactModule(component, el, el[0].dataset);
    },
  },
];

$(() => {
  // IE 11 Polyfills
  if ( typeof window.CustomEvent !== 'function' ) {
    // eslint-disable-next-line no-undef
    NodeList.prototype.forEach = Array.prototype.forEach;
    // Custom Event Polyfill
    const CustomEvent = (event, params) => {
      params = params || {bubbles: false, cancelable: false, detail: null};
      const evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
    };
    window.CustomEvent = CustomEvent;
  }
  /**
   * Viewport Switch
   */
  const switchAllowedList = ['powersports.honda.com.verndale-qa.com', 'powersports.honda.com.verndale-local.com', 'localhost'];
  // Enable Viewport Switch only in allowed hosts
  if (switchAllowedList.includes(window.location.hostname)) {
    // Add Viewport switcher
    let initialMode = 'normal';
    if (window.location.search.includes('viewport=wide')) {
      document.querySelector('body').classList.add('wide');
      initialMode = 'wide';
    }
    document.querySelector('body').insertAdjacentHTML('beforeend', `
  <div class="viewport-switch">
      <button class="btn btn-outline ${initialMode !== 'wide' ? 'active' : ''}" data-value="normal">1200px</button>
      <button class="btn btn-outline ${initialMode === 'wide' ? 'active' : ''}" data-value="wide">1600px</button>
  </div>
`);
    // Add Viewport Buttons Events
    const viewportButtons = [...document.querySelectorAll('.viewport-switch button')];
    viewportButtons.forEach(switchBtn => {
      switchBtn.addEventListener('click', e => {
        const target = e.currentTarget;
        const value = target.dataset.value;
        viewportButtons.forEach(btn => btn.classList.remove('active'));
        // Switch back to normal (1200px)
        if (value === 'normal') {
          history.pushState(
            null,
            null,
            `${window.location.pathname}?viewport=normal`
          );
          document.querySelector('body').classList.remove('wide');
          // Switch back to wide (1600px)
        } else if (value === 'wide') {
          history.pushState(
            null,
            null,
            `${window.location.pathname}?viewport=wide`
          );
          document.querySelector('body').classList.add('wide');
        }
        target.classList.add('active');
      });
    });
  }
  /**
   * Viewport Switch
   */
  create(modules);
  createTooltips(['colorswatch-swatches-item', 'filter-tip']);

  createMsrpTooltips(['.disclaimer']);

  $('.footer-main-title').on('click', e => {
    $(e.currentTarget)
      .parent()
      .toggleClass('show-footer');
  });

  $(document).on('click', '.events-listing-item tr', e => {
    const $row = $(e.currentTarget);
    const $link = $row.find('a');

    if ($link.length) window.location = $link.attr('href');
  });

  // Hide tooltip on scroll
  window.addEventListener(
    'scroll',
    _throttle(() => {
      const tp = $('.tooltip[aria-hidden="false"]');
      if (tp.length) {
        tp.find('.tooltip-close').not('.js-open-on-scroll').click();
      }
    }, 500)
  );

  // Change tabindex of the skip to button becuase of markup order
  if ($('body').hasClass('homepage')) {
    $('#drop4').attr('tabindex', '1');
  }

  // Remove the role and title attribute from skipToMenu to meet ADA
  if ($('#skipToMenu').length) {
    $('#skipToMenu').removeAttr('title');
    $('#skipToMenu').removeAttr('role');
  }

  $(document).ready(() => {
  // Add title for links that open a new window
    $('a[target="_blank"]').each((index, element) => {
      const $element = $(element);
      let text = $element.text().trim();
      text = text.replace(/\s{2,}/g, '');

      $element.attr('title', `${text} (Opens in a new window)`);
    });
    // Add title for iframes without one
    $('iframe').each((index, element) => {
      const $element = $(element);
      if (!$element.attr('title')) {
        $(element).attr('title', `iframe ${index}`);
      }
    });

    $('a[data-download]').on('click', e => {
      dmtTrackWithDataLayer({
        event: 'file-download',
        type: 'bap-file-download-click',
        label: `bap-file-download-click:${$(e.currentTarget).data('download')}`,
        category: `global-bap-file-download-click`,
        custom: {
          'event_metadata.download_title': $(e.currentTarget).data('download'),
        },
      });
    });

    // The following code is used to grab files from staging when developing on localhost
    // This is not needed in production and we just use it in certain test cases
    // You can comment out the code below if you don't need it and work with the files on your local machine
    // IMPORTANT: You need to connect through VPN to access the staging files
    if (window.location.hostname === 'localhost') {
      $(document).on('lazybeforeunveil', e => {
        const src = $(e.target).data('src');
        if (src) {
          $(e.target).attr('data-src', `https://staging.ca.powersports.honda.com/${src}`);
        }
      });

      $(() => {
        $('img').each(element => {
          const $element = $(element);
          const src = $element.attr('src');
          if ($element.attr('data-src')) return; // skip lazy loaded images -already done in previous function
          if (src) $element.attr('src', `https://staging.ca.powersports.honda.com/${src}`);
        });
      });
    }
  });
});
