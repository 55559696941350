import types from './types';

const initialState = {
  modelHierarchy: null,
  modelHierarchyLoading: false,
  modelHierarchyError: null,
  modelInventory: null,
  modelInventoryLoading: false,
  modelInventoryError: null,
  modelsFilterResult: [],
  backToDefaultSearchParams: null,
  locationDistanceLoaded: 0,
};
export const updateDefaultScreenApiDataReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.MODEL_HIERARCHY_FETCH_PENDING: {
    return {
      ...state,
      modelHierarchyLoading: true,
      modelHierarchyError: null,
    };
  }
  case types.MODEL_HIERARCHY_FETCH_FULFILLED: {
    return {
      ...state,
      modelHierarchyLoading: false,
      modelHierarchy: action.payload,
    };
  }
  case types.MODEL_HIERARCHY_FETCH_REJECTED: {
    return {
      ...state,
      modelHierarchyLoading: false,
      modelHierarchyError: action.payload,
    };
  }
  case types.MODEL_INVENTORY_FETCH_PENDING: {
    return {
      ...state,
      modelInventoryLoading: true,
      modelInventoryError: null,
    };
  }
  case types.MODEL_INVENTORY_FETCH_FULFILLED: {
    const {result, locationDistanceLoaded} = action.payload;
    return {
      ...state,
      modelInventoryLoading: false,
      modelInventory: result,
      locationDistanceLoaded,
    };
  }
  case types.MODEL_INVENTORY_FETCH_REJECTED: {
    const {error} = action.payload;
    return {
      ...state,
      modelInventoryLoading: false,
      modelInventoryError: error,
    };
  }
  case types.MODEL_FILTER_RESULT: {
    return {
      ...state,
      modelsFilterResult: action.payload,
    };
  }
  case types.CHANGE_BACK_TO_DEFAULT_SEARCH_PARAMS: {
    return {
      ...state,
      backToDefaultSearchParams: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
