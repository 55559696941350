import types from './types';

const initialState = {
  closestDealers: null,
  closestDealersLoading: false,
  closestDealersError: null,
  closestDealersFilterResult: [],
  closestDealersRefineSearch: [],
  isChanged: false,
};
export const updateClosestDealersReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.DEALERS_FETCH_PENDING: {
    return {
      ...state,
      closestDealersLoading: true,
      closestDealersError: null,
    };
  }
  case types.DEALERS_FETCH_FULFILLED: {
    return {
      ...state,
      closestDealersLoading: false,
      closestDealers: action.payload,
    };
  }
  case types.DEALERS_FETCH_REJECTED: {
    return {
      ...state,
      closestDealersLoading: false,
      closestDealersError: action.payload,
    };
  }
  case types.DEALERS_FILTER_RESULT: {
    return {
      ...state,
      closestDealersFilterResult: action.payload,
    };
  }
  case types.CLOSEST_DEALERS_REFINE_SEARCH: {
    if (state.closestDealersRefineSearch.includes(action.payload)) {
      return {
        ...state,
        closestDealersRefineSearch: state.closestDealersRefineSearch.filter(dealerId => dealerId !== action.payload),
      };
    }
    return {
      ...state,
      closestDealersRefineSearch: [...state.closestDealersRefineSearch, action.payload],
    };
  }
  case types.SET_CLOSEST_DEALERS_REFINE_SEARCH: {
    return {
      ...state,
      closestDealersRefineSearch: action.payload,
    };
  }
  case types.CLOSEST_DEALERS_REFINE_SEARCH_IS_CHANGED: {
    return {
      ...state,
      isChanged: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
