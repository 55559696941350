export const SET_OFFERS = 'SET_OFFERS';
export const SELECT_OFFER = 'SELECT_OFFER';
export const DESELECT_OFFER = 'DESELECT_OFFER';
export const DESELECT_ALL_OFFERS = 'DESELECT_ALL_OFFERS';
export const UPDATE_ZIP = 'UPDATE_ZIP';

export const setOffers = payload => ({
  type: SET_OFFERS,
  payload,
});

export const selectOffer = payload => ({
  type: SELECT_OFFER,
  payload,
});

export const deSelectOffer = payload => ({
  type: DESELECT_OFFER,
  payload,
});

export const deselectAllOffers = payload => ({
  type: DESELECT_ALL_OFFERS,
  payload,
});

export const updateZip = payload => ({
  type: UPDATE_ZIP,
  payload,
});
