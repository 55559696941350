import types from './types';

const initialState = {
  trimsSelected: [],
  trimsAvailableSelected: [],
  isChanged: false,
};
export const updateTrimStateReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_TRIMS_SELECTED: {
    const trimsChange = action.payload;
    const trimsSelectedClone = [...state.trimsSelected];
    trimsChange.forEach(trim => {
      if (trimsSelectedClone.includes(trim)) {
        trimsSelectedClone.splice(trimsSelectedClone.indexOf(trim), 1);
      } else {
        trimsSelectedClone.push(trim);
      }
    });

    return {...state, trimsSelected: [...trimsSelectedClone]};
  }
  case types.CHANGE_TRIMS_AVAILABLE_SELECTED: {
    return {...state, trimsAvailableSelected: [...action.payload]};
  }
  case types.CHANGE_TRIMS_SELECTED_IS_CHANGED: {
    return {...state, isChanged: action.payload};
  }
  case types.SET_TRIMS_SELECTED: {
    return {...state, trimsSelected: [...action.payload]};
  }
  case types.RESET_TRIMS_STATE: {
    return {...initialState};
  }
  default: {
    return state;
  }
  }
};
