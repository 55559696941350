import {validZipCode} from '../../utils';
import types from './types';

export const validateZipCodeMiddleware = store => next => action => {
  if (action.type === types.CHANGE_LOCATION_ZIP_CODE_SELECTED) {
    const zipCode = action.payload;
    const {locationSelected} = store.getState();
    const {zipCode: prevZipCode} = locationSelected;
    if (!validZipCode(zipCode) && prevZipCode !== zipCode) {
      return store.dispatch({
        type: types.CHANGE_LOCATION_ZIP_CODE_SELECTED,
        payload: prevZipCode,
      });
    }
  }
  return next(action);
};
