export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const setCategories = payload => ({
  type: SET_CATEGORIES,
  payload,
});

export const selectCategory = payload => ({
  type: SELECT_CATEGORY,
  payload,
});
