export const SET_TERM = 'SET_TERM';
export const SET_MONTHLY = 'SET_MONTHLY';
export const SET_ANNUAL = 'SET_ANNUAL';
export const SET_DOWN = 'SET_DOWN';

export const setTerm = payload => ({
  type: SET_TERM,
  payload,
});

export const setMonthly = payload => ({
  type: SET_MONTHLY,
  payload,
});

export const setAnnual = payload => ({
  type: SET_ANNUAL,
  payload,
});

export const setDown = payload => ({
  type: SET_DOWN,
  payload,
});
