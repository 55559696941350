export const VEHICLE_FILTER_KEY = 'vehicle';
export const CATEGORY_FILTER_KEY = 'category';
export const CATEGORY_SORT_KEY = 'category';
export const DISTANCE_SORT_KEY = 'distance';
export const PRICE_HIGH_LOW_SORT_KEY = 'price-high-low';
export const PRICE_LOW_HIGH_SORT_KEY = 'price-low-high';
export const DEFAULT_SORT_KEY = 'default';
export const CURRENT_YEAR_FILTER_KEY_NAME = 'Current';
export const PREVIOUS_YEAR_FILTER_KEY_NAME = 'Previous';
export const YEAR_FILTER_KEY = 'modelYear';
export const TRIM_FILTER_KEY = 'trims';
export const COLOR_FILTER_KEY = 'colors';
export const LOCATION_DISTANCES = window.HONDA_INVENTORY.defaultValues.locationDistance.replace(/\s/g, '').split(',').map(value => ({
  label: `${value} ${window.HONDA_INVENTORY.labels.milesText}`,
  val: parseInt(value),
}));
export const SEGMENT_SEARCH_PARAM_KEY = 'segment';
export const CATEGORY_SEARCH_PARAM_KEY = 'category';
export const FAMILY_SEARCH_PARAM_KEY = 'family';
export const PRICE_SEARCH_PARAM_KEY = 'price';
export const ZIP_SEARCH_PARAM_KEY = 'zip';
export const MILES_RADIUS_SEARCH_PARAM_KEY = 'milesradius';
export const SORT_BY_SEARCH_PARAM_KEY = 'sortby';
export const DEALER_SEARCH_PARAM_KEY = 'dealer';
export const TRIMS_SEARCH_PARAM_KEY = 'trims';
export const COLORS_SEARCH_PARAM_KEY = 'colors';
export const COLOR_SEARCH_PARAM_KEY = 'color';
export const MODEL_SEARCH_PARAM_KEY = 'model';
export const MODEL_ID_SEARCH_PARAM_KEY = 'modelid';
export const DEALER_TYPE_SEARCH_PARAM_KEY = 'dealertype';
export const YEAR_SEARCH_PARAM_KEY = 'modelyear';
export const VIN_STATUS_SEARCH_PARAM_KEY = 'vinstatus';
export const SORT_OPTIONS = [
  {
    label: window.HONDA_INVENTORY?.labels?.sortByCategory,
    val: CATEGORY_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByDistance,
    val: DISTANCE_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByPriceDESC,
    val: PRICE_LOW_HIGH_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByPriceACS,
    val: PRICE_HIGH_LOW_SORT_KEY,
  },
];
export const SORT_OPTIONS_RESULTS_SCREEN = [
  {
    label: window.HONDA_INVENTORY?.labels?.sortByDefault,
    val: DEFAULT_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByDistance,
    val: DISTANCE_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByPriceDESC,
    val: PRICE_LOW_HIGH_SORT_KEY,
  },
  {
    label: window.HONDA_INVENTORY?.labels?.sortByPriceACS,
    val: PRICE_HIGH_LOW_SORT_KEY,
  },
];
export const DEFAULT_SORT_BY = CATEGORY_SORT_KEY;
export const DEFAULT_SORT_BY_RESULTS_SCREEN = DEFAULT_SORT_KEY;
export const SELECTBOX_TYPE_NORMAL = 'NORMAL';
export const SELECTBOX_TYPE_COLOR = 'COLOR';
export const CONTACT_TYPE_PH = 'ph';
export const CONTACT_TYPE_ALTERNATE = 'alternate';
export const CONTACT_TYPE_DEFAULT = 'default';
export const RADIUS_POWERHOUSE_FIND = LOCATION_DISTANCES[LOCATION_DISTANCES.length - 1].val;
export const IMG_BASE_64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC';

let inventoryStatus = {};
try {
  inventoryStatus = JSON.parse(window.HONDA_INVENTORY?.labels?.inventoryStatus);
} catch (error) {
  inventoryStatus = {
    INSTOCK: {
      label: 'In Stock',
      classCss: 'instock',
    },
    OUTOFSTOCK: {
      label: 'Out of Stock',
      classCss: 'out-of-stock',
    },
    PEND: {
      label: 'Pending Sale',
      classCss: 'pending-sale',
    },
    SOON: {
      label: 'Available Soon',
      classCss: 'available-soon',
    },
    ACCESSORY: {
      label: 'In-Stock (Accessorized)',
      classCss: 'in-stock-accessorized',
    },
    DEMO: {
      label: 'In-Stock (Demo)',
      classCss: 'in-stock-demo',
    },
  };
}
export const INVENTORY_STATUS = inventoryStatus;
export const MAX_PRICE_FILTER_DEFAULT = 200000;
export const MIN_PRICE_FILTER_DEFAULT = 0;
export const NUMBER_VERY_BIG = 9999999;
export const DEFAULT_ZIP_CODE = window.HONDA_INVENTORY?.defaultValues?.zipCode || '90001';
export const RADIUS_BEST = Number(window.HONDA_INVENTORY?.defaultValues?.radiusBest) || 250;
