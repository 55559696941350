import {LOCATION_DISTANCES} from '../../constants';
import types from './types';

const initialState = {
  distance: LOCATION_DISTANCES[0].val,
  zipCode: '',
  zipCodeLoading: false,
  zipCodeError: null,
  isCanAutoExtendDistance: true,
  isChangedDistance: false,
  isChangedZipCode: false,
  zipCodePrevious: '',
  isDistanceDefaultBeSet: false,
};
export const updateLocationSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_LOCATION_DISTANCE_SELECTED: {
    return {...state, distance: action.payload};
  }
  case types.CHANGE_LOCATION_ZIP_CODE_SELECTED: {
    return {
      ...state,
      zipCode: action.payload,
    };
  }
  case types.ZIP_CODE_FETCH_PENDING: {
    return {
      ...state,
      zipCodeLoading: true,
      zipCodeError: null,
    };
  }
  case types.ZIP_CODE_FETCH_FULFILLED: {
    return {
      ...state,
      zipCodeLoading: false,
      zipCode: action.payload,
    };
  }
  case types.ZIP_CODE_FETCH_REJECTED: {
    return {
      ...state,
      zipCodeLoading: false,
      zipCodeError: action.payload,
    };
  }
  case types.CHANGE_IS_CAN_AUTO_EXTEND_DISTANCE: {
    return {
      ...state,
      isCanAutoExtendDistance: action.payload,
    };
  }
  case types.CHANGE_IS_CHANGED_DISTANCE: {
    return {
      ...state,
      isChangedDistance: action.payload,
    };
  }
  case types.CHANGE_IS_CHANGED_ZIP_CODE: {
    return {
      ...state,
      isChangedZipCode: action.payload,
    };
  }
  case types.CHANGE_LOCATION_ZIP_CODE_PREVIOUS_SELECTED: {
    return {
      ...state,
      zipCodePrevious: action.payload,
    };
  }
  case types.CHANGE_IS_DISTANCE_DEFAULT_BE_SET: {
    return {
      ...state,
      isDistanceDefaultBeSet: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
