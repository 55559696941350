import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {updateDefaultScreenApiDataReducer} from './default-screen-api-data/reducer';
import {updateResultsScreenApiDataReducer} from './results-screen-api-data/reducer';
import {updateVehicleSelectedReducer} from './vehicle-type/reducer';
import {updateModelYearReducer} from './model-years/reducer';
import {updateCategorySelectedReducer} from './categories/reducer';
import {updateLocationSelectedReducer} from './location/reducer';
import {isMobileReducer} from './is-mobile/reducer';
import {sortByReducer} from './models-sorting/reducer';
import {priceRangeReducer} from './price/reducer';
import {updateClosestDealersReducer} from './dealers/reducer';
import {updateColorStateReducer} from './colors/reducer';
import {updateTrimStateReducer} from './trims/reducer';
import {updateContactScreenDataReducer} from './contact-screen/reducer';

export default combineReducers({
  vehicleSelected: updateVehicleSelectedReducer,
  modelYear: updateModelYearReducer,
  categorySelected: updateCategorySelectedReducer,
  locationSelected: updateLocationSelectedReducer,
  isMobileScreen: isMobileReducer,
  modelsSorting: sortByReducer,
  defaultScreenApiData: updateDefaultScreenApiDataReducer,
  resultsScreenApiData: updateResultsScreenApiDataReducer,
  contactScreenData: updateContactScreenDataReducer,
  priceRange: priceRangeReducer,
  routing: routerReducer,
  closetDealer: updateClosestDealersReducer,
  colorState: updateColorStateReducer,
  trimState: updateTrimStateReducer,
});
