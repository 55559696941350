import types from './types';

const initialState = {
  modelsSearch: null,
  modelsSearchLoading: false,
  modelsSearchError: null,
  modelsSearchInventory: null,
  modelsSearchInventoryLoading: false,
  modelsSearchInventoryError: null,
  modelsSearchFilterResult: [],
  modelsSearchFullData: null,
  modelsSearchLoaded: {},
};
export const updateResultsScreenApiDataReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SEARCH_MODEL_FETCH_PENDING: {
    return {
      ...state,
      modelsSearchLoading: true,
      modelsSearchError: null,
    };
  }
  case types.SEARCH_MODEL_FETCH_FULFILLED: {
    return {
      ...state,
      modelsSearchLoading: false,
      modelsSearch: action.payload,
    };
  }
  case types.SEARCH_MODEL_FETCH_REJECTED: {
    return {
      ...state,
      modelsSearchLoading: false,
      modelsSearchError: action.payload,
    };
  }
  case types.SEARCH_MODEL_INVENTORY_FETCH_PENDING: {
    return {
      ...state,
      modelsSearchInventoryLoading: true,
      modelsSearchInventoryError: null,
    };
  }
  case types.SEARCH_MODEL_INVENTORY_FETCH_FULFILLED: {
    return {
      ...state,
      modelsSearchInventoryLoading: false,
      modelsSearchInventory: action.payload,
    };
  }
  case types.SEARCH_MODEL_INVENTORY_FETCH_REJECTED: {
    return {
      ...state,
      modelsSearchInventoryLoading: false,
      modelsSearchInventoryError: action.payload,
    };
  }
  case types.SEARCH_MODEL_FILTER_RESULT: {
    return {
      ...state,
      modelsSearchFilterResult: action.payload,
    };
  }
  case types.SEARCH_MODEL_FILL_DATA: {
    return {
      ...state,
      modelsSearchFullData: [...action.payload],
    };
  }
  case types.RESET_RESULTS_SCREEN_API_DATA: {
    return {
      ...initialState,
      modelsSearchLoaded: state.modelsSearchLoaded,
    };
  }
  case types.RESULTS_SCREEN_API_DATA_LOADED: {
    const {key, value} = action.payload;
    return {
      ...state,
      modelsSearchLoaded: {
        ...state.modelsSearchLoaded,
        [key]: value,
      },
    };
  }
  default: {
    return state;
  }
  }
};
