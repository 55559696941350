import {SET_PAGE} from './action';

export default function(state = 0, action) {
  switch (action.type) {
  case SET_PAGE: {
    return action.payload;
  }
  default: {
    return state;
  }
  }
}
