import {ENDPOINTS} from './../../../../constants/';
export const SAVE_LOADING = 'SAVE_LOADING';
export const SAVE_COMPLETE = 'SAVE_COMPLETE';
export const SAVE_ANIMATE = 'SAVE_ANIMATE';
export const CLOSE_BUILD = 'CLOSE_BUILD';
export const UPDATE_BUILD_ID = 'UPDATE_BUILD_ID';

const PREFIX = window.HONDA_BP || {};
const HONDA = PREFIX.GLOBAL_INFO;

export function onBuildAnimateComplete(data) {
  return {
    type: SAVE_COMPLETE,
    payload: data,
  };
}

export function onBuildAnimate(data) {
  return {
    type: SAVE_ANIMATE,
    payload: data,
  };
}

export function onBuildSubmit() {
  return {
    type: SAVE_LOADING,
  };
}


export function closeMessage() {
  return {
    type: CLOSE_BUILD,
  };
}

export function submitBuild(info) {
  return dispatch => {
    dispatch(onBuildSubmit());
    $.ajax({
      url: ENDPOINTS.saveBuild,
      data: {...info, ...HONDA.apiInfo},
      type: 'POST',

    })
      .done( response => {
        if (response.success) dispatch(onBuildAnimate( response.message ));
      }
      ).then(response => {
        setTimeout(() => {
          dispatch(onBuildAnimateComplete(response));
        }, 1500);
      }
      );
  };
}

export function updateBuildId(data) {
  return {
    type: UPDATE_BUILD_ID,
    payload: data,
  };
}
