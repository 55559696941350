import {SET_CATEGORIES} from './actions';
import {SELECT_CATEGORY} from './actions';

export const categories = (state = null, action) => {
  switch (action.type) {
  case SET_CATEGORIES: {
    return {...state, ...action.payload};
  }

  case SELECT_CATEGORY: {
    return {...state, selected: action.payload};
  }

  default: {
    return state;
  }
  }
};
