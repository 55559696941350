import types from './types';

const initialState = {
  isChanged: false,
  value: null,
};
export const updateVehicleSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_VEHICLE_TYPE_SELECTED: {
    return {
      ...state,
      value: action.payload,
    };
  }
  case types.CHANGE_VEHICLE_TYPE_IS_CHANGED: {
    return {
      ...state,
      isChanged: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
