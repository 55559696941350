import {dmtTrackWithDataLayer} from '../../../../helpers';

export const SET_THANKYOU = 'SET_THANKYOU';

export function setThankyou(shown) {
  dmtTrackWithDataLayer({
    event: 'raq-complete',
    type: 'raq-complete',
    label: 'raq-complete:thank-you',
    category: 'global-raq-complete',
  });
  return {
    type: SET_THANKYOU,
    payload: shown,
  };
}
