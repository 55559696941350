export const SET_COLOR = 'SET_COLOR';

export function setColor(color) {
  const colorString = JSON.stringify( color );
  window.localStorage.setItem('color', colorString );

  return dispatch =>
    dispatch({
      type: SET_COLOR,
      payload: color,
    });
}
