import {SET_TERM, SET_FIELD} from './actions';

const {options} = window.HONDA_FINANCIAL_CALCULATOR.views.calculate.fields.term;

const initialState = {
  term: options.filter(x => x === 60).length ? 60 : options[0],
  annual: '',
  sellingPrice: '',
  allowance: '',
  tradeIn: '',
  downPayment: '',
  balance: '',
};

export const calculate = (state = initialState, action) => {
  switch (action.type) {
  case SET_TERM: {
    return {...state, term: action.payload};
  }

  case SET_FIELD: {
    return {...state, [action.payload.field]: action.payload.value};
  }

  default: {
    return state;
  }
  }
};
