import {
  SET_OFFERS,
  SELECT_OFFER,
  DESELECT_OFFER,
  DESELECT_ALL_OFFERS,
  UPDATE_ZIP,
} from './actions';

const {initialInfo} = window.HONDA_FINANCIAL_CALCULATOR;
const initialState = {
  offers: null,
  zip: '',
  selected: initialInfo.offers || [],
};

export const offers = (state = initialState, action) => {
  switch (action.type) {
  case SET_OFFERS: {
    return {...state, offers: action.payload};
  }

  case SELECT_OFFER: {
    return {
      ...state,
      selected: [...state.selected, action.payload],
    };
  }

  case DESELECT_OFFER: {
    return {
      ...state,
      selected: state.selected.filter(x => x.id !== action.payload[0].id),
    };
  }

  case DESELECT_ALL_OFFERS: {
    return {
      ...state,
      selected: [],
    };
  }

  case UPDATE_ZIP: {
    return {
      ...state,
      zip: action.payload,
    };
  }

  default: {
    return state;
  }
  }
};
