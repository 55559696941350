import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {select} from './select/reducer';
import {offers} from './offers/reducer';
import {calculate} from './calculate/reducer';

export default combineReducers({
  select,
  offers,
  calculate,
  routing: routerReducer,
});
