export const SET_MODELS = 'SET_MODELS';
export const SELECT_MODEL = 'SELECT_MODEL';
export const SET_TRIMS = 'SET_TRIMS';
export const SELECT_TRIM = 'SELECT_TRIM';

export const setModels = payload => ({
  type: SET_MODELS,
  payload,
});

export const selectModel = payload => {
  const {Family, ModelId, Segment, years} = payload;
  window.dataLayer['Model.model_name'] = Family.toLowerCase();
  window.dataLayer['Model.model_year'] = years[0];
  window.dataLayer['Model.model_id'] = ModelId;
  window.dataLayer['Model.body_style'] = Segment;

  return ({type: SELECT_MODEL, payload});
};

export const setTrims = payload => ({
  type: SET_TRIMS,
  payload,
});

export const selectTrim = payload => {
  const {ModelName, ModelYear} = payload;
  window.dataLayer['Model.model_year'] = ModelYear;
  window.dataLayer['Model.model_trim'] = ModelName.toLowerCase();

  return ({type: SELECT_TRIM, payload});
};
