export default {
  SEARCH_MODEL_FETCH_PENDING: 'SEARCH_MODEL_FETCH_PENDING',
  SEARCH_MODEL_FETCH_FULFILLED: 'SEARCH_MODEL_FETCH_FULFILLED',
  SEARCH_MODEL_FETCH_REJECTED: 'SEARCH_MODEL_FETCH_REJECTED',
  SEARCH_MODEL_INVENTORY_FETCH_PENDING: 'SEARCH_MODEL_INVENTORY_FETCH_PENDING',
  SEARCH_MODEL_INVENTORY_FETCH_FULFILLED: 'SEARCH_MODEL_INVENTORY_FETCH_FULFILLED',
  SEARCH_MODEL_INVENTORY_FETCH_REJECTED: 'SEARCH_MODEL_INVENTORY_FETCH_REJECTED',
  SEARCH_MODEL_FILTER_RESULT: 'SEARCH_MODEL_FILTER_RESULT',
  SEARCH_MODEL_FILL_DATA: 'SEARCH_MODEL_FILL_DATA',
  RESET_RESULTS_SCREEN_API_DATA: 'RESET_RESULTS_SCREEN_API_DATA',
  RESULTS_SCREEN_API_DATA: 'RESULTS_SCREEN_API_DATA',
  RESULTS_SCREEN_API_DATA_LOADED: 'RESULTS_SCREEN_API_DATA_LOADED',
};
