import contact from './contact/reducer';
import selectedDealers from './selectedDealers/reducer';
import selectedModel from './selectedModel/reducer';
import selectedTrim from './selectedTrim/reducer';
import current from './setCurrentPage/reducer';
import thankyou from './setThankyou/reducer';
import footer from './setFooter/reducer';
import loader from './loader/reducer';
import modelPage from './setModelPage/reducer';
import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

export default combineReducers({
  contact,
  selectedDealers,
  selectedModel,
  selectedTrim,
  current,
  thankyou,
  footer,
  loader,
  modelPage,
  routing: routerReducer,
});
