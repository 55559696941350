export default {
  CHANGE_CONTACT_COLOR_SELECTED: 'CHANGE_CONTACT_COLOR_SELECTED',
  CHANGE_CONTACT_TRIM_SELECTED: 'CHANGE_CONTACT_TRIM_SELECTED',
  CHANGE_CONTACT_MODEL_SELECTED: 'CHANGE_CONTACT_MODEL_SELECTED',
  CHANGE_CONTACT_TYPE: 'CHANGE_CONTACT_TYPE',
  CHANGE_CONTACT_LOCAL_DEALER_SELECTED: 'CHANGE_CONTACT_LOCAL_DEALER_SELECTED',
  CHANGE_CONTACT_MAIN_DEALER: 'CHANGE_CONTACT_MAIN_DEALER',
  CHANGE_CONTACT_OTHER_DEALERS_AVAILABLE: 'CHANGE_CONTACT_OTHER_DEALERS_AVAILABLE',
  CHANGE_CONTACT_DEALERS_SELECTED: 'CHANGE_CONTACT_DEALERS_SELECTED',
  CHANGE_CONTACT_TRIMS_AVAILABLE: 'CHANGE_CONTACT_TRIMS_AVAILABLE',
  CHANGE_CONTACT_COLORS_AVAILABLE: 'CHANGE_CONTACT_COLORS_AVAILABLE',
  RESET_CONTACT_STATE: 'RESET_CONTACT_STATE',
  SUBMIT_CONTACT_PENDING: 'SUBMIT_CONTACT_PENDING',
  SUBMIT_CONTACT_FULFILLED: 'SUBMIT_CONTACT_FULFILLED',
  SUBMIT_CONTACT_REJECTED: 'SUBMIT_CONTACT_REJECTED',
  CHANGE_DEALERS_INVENTORY_STATUS: 'CHANGE_DEALERS_INVENTORY_STATUS',
  CHANGE_DEALER_MAIN_VIN_STATUS: 'CHANGE_DEALER_MAIN_VIN_STATUS',
};
