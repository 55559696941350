import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import trim from './setTrim/reducer';
import loader from './loader/reducer';
import color from './setColor/reducer';
import data from './setData/reducer';
import saveBuild from './saveBuild/reducer';

export default combineReducers({
  trim,
  loader,
  color,
  data,
  saveBuild,
  routing: routerReducer,
});
