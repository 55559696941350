import {SHOW_LOADER, HIDE_LOADER} from './actions';

const initialState = {
  show: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case SHOW_LOADER: {
    return {
      show: true,
    };
  }

  case HIDE_LOADER: {
    return initialState;
  }

  default: {
    return state;
  }
  }
}
