import {SET_MODELS, SELECT_MODEL, SET_TRIMS, SELECT_TRIM} from './actions';

const {initialInfo} = window.HONDA_FINANCIAL_CALCULATOR;
const initialState = {
  models: null,
  trims: null,
  selected: {
    model: initialInfo.model || {},
    trim: initialInfo.trim || {},
  },
};

export const select = (state = initialState, action) => {
  switch (action.type) {
  case SET_MODELS: {
    return {...state, models: action.payload};
  }

  case SELECT_MODEL: {
    return {
      ...state,
      selected: {...state.selected, model: action.payload},
    };
  }

  case SET_TRIMS: {
    return {...state, trims: action.payload};
  }

  case SELECT_TRIM: {
    return {
      ...state,
      selected: {...state.selected, trim: action.payload},
    };
  }

  default: {
    return state;
  }
  }
};
