import types from './types';

const initialState = {
  yearSelected: [],
  isChanged: false,
};
export const updateModelYearReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_YEAR_SELECTED: {
    if (state.yearSelected.includes(action.payload)) {
      return {...state, yearSelected: state.yearSelected.filter(year => year !== action.payload)};
    }
    return {...state, yearSelected: [...state.yearSelected, action.payload]};
  }
  case types.CHANGE_YEAR_SELECTED_IS_CHANGED: {
    return {
      ...state,
      isChanged: action.payload,
    };
  }
  case types.SET_YEAR_SELECTED: {
    return {
      ...state,
      yearSelected: action.payload,
    };
  }
  case types.RESET_YEAR_STATE: {
    return {...initialState};
  }
  default: {
    return state;
  }
  }
};
