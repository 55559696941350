import {SET_DATA} from './actions';
const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_DATA: {
    return action.payload;
  }
  default: {
    return state;
  }
  }
}
