import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from '../reducers/';

import {validateZipCodeMiddleware} from '../reducers/location/middleware';
const middleware = [promise(), thunk];

if (process.env.NODE_ENV === 'development') {
  const {logger} = require('redux-logger');

  middleware.push(logger);
}

export default createStore(reducers, applyMiddleware(...middleware, validateZipCodeMiddleware));
