import {DEFAULT_SORT_BY} from '../../constants';
import types from './types';

const initialState = {
  isChanged: false,
  value: DEFAULT_SORT_BY,
};

export const sortByReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SORT_BY: {
    return {
      isChanged: true,
      value: action.payload,
    };
  }
  case types.SORT_BY_WITHOUT_UPDATE_IS_CHANGED: {
    return {
      ...state,
      value: action.payload,
    };
  }
  case types.UPDATE_SORT_BY_IS_CHANGED: {
    return {
      ...state,
      isChanged: false,
    };
  }
  default:
    return state;
  }
};
