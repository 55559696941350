import {SET_TERM, SET_MONTHLY, SET_ANNUAL, SET_DOWN} from './actions';

const {options} = window.HONDA_BUDGET_CALCULATOR.calculatorFields.term;

const initialState = {
  term: options.filter(x => x === 60).length ? 60 : options[0],
  monthly: '',
  annual: '',
  down: '',
};

export const calculate = (state = initialState, action) => {
  switch (action.type) {
  case SET_TERM: {
    return {...state, term: action.payload};
  }

  case SET_MONTHLY: {
    return {...state, monthly: action.payload};
  }

  case SET_ANNUAL: {
    return {...state, annual: action.payload};
  }

  case SET_DOWN: {
    return {...state, down: action.payload};
  }

  default: {
    return state;
  }
  }
};
