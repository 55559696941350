export const SET_TRIM = 'SET_TRIM';
export const SET_MODEL_STATE = 'SET_MODEL_STATE';
export const SET_HIDE_ACCESSORIES_TAB = 'SET_HIDE_ACCESSORIES_TAB';
export const SET_USE_CONFIGURATOR = 'SET_USE_CONFIGURATOR';

export function setTrim({
  ModelId, TotalPrice, ModelYear, ModelName, ModelTextList,
  DestinationHandling, MsrpDisclaimer, ImageDisclaimer, FreightSurcharge, ModelAttributeList,
}) {
  if (ModelName) {
    window.dataLayer['Model.model_trim'] = ModelName.toLowerCase();
    window.dataLayer['Model.model_year'] = ModelYear;
    window.dataLayer['Model.model_id'] = ModelId;
  } else {
    window.dataLayer['Model.model_trim'] = '';
    window.dataLayer['Model.model_year'] = '';
    window.dataLayer['Model.model_id'] = '';
  }
  return dispatch => dispatch({
    type: SET_TRIM,
    payload: {
      ModelId,
      TotalPrice,
      ModelYear,
      ModelName,
      ModelTextList,
      DestinationHandling,
      MsrpDisclaimer,
      ImageDisclaimer,
      FreightSurcharge,
      ModelAttributeList,
    },
  });
}

export const setModelState = payload => ({
  type: SET_MODEL_STATE,
  payload,
});

export const setHideAccessoriesTab = payload => ({
  type: SET_HIDE_ACCESSORIES_TAB,
  payload,
});

export const setUseConfigurator = payload => ({
  type: SET_USE_CONFIGURATOR,
  payload,
});
