import types from './types';

const initialState = {
  colorsSelected: [],
  colorsAvailableSelected: [],
  isChanged: false,
};
export const updateColorStateReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_COLOR_SELECTED: {
    const colorsChange = action.payload;
    const colorsSelectedClone = [...state.colorsSelected];
    colorsChange.forEach(color => {
      if (colorsSelectedClone.includes(color)) {
        colorsSelectedClone.splice(colorsSelectedClone.indexOf(color), 1);
      } else {
        colorsSelectedClone.push(color);
      }
    });

    return {...state, colorsSelected: [...colorsSelectedClone]};
  }
  case types.CHANGE_COLOR_AVAILABLE_SELECTED: {
    return {...state, colorsAvailableSelected: [...action.payload]};
  }
  case types.CHANGE_COLOR_SELECTED_IS_CHANGED: {
    return {...state, isChanged: action.payload};
  }
  case types.SET_CHANGE_COLOR_SELECTED: {
    return {...state, colorsSelected: [...action.payload]};
  }
  case types.RESET_COLORS_STATE: {
    return {...initialState};
  }
  default: {
    return state;
  }
  }
};
