export const SET_MODEL = 'SET_MODEL';

export function setModel(model) {
  if (window.data_Layer && window.data_Layer.length > 0) {
    window.data_Layer[0].RAQ_modelName = model.Family;
  }
  if (window.dataLayer && model.Family) {
    window.dataLayer['Model.model_name'] = model.Family.toLowerCase();
  }
  return {
    type: SET_MODEL,
    payload: model,
  };
}
