// const domain = window.location.hostname === 'localhost' ? 'https://private-3f0f78-hondapowersports1.apiary-mock.com' : '';
const domain = window.location.hostname === 'localhost' ? 'https://staging.ca.powersports.honda.com' : '';
const API_PREFIX = `${domain}/api/custom`;


// endpoints
export const ENDPOINTS = {
  // BASE
  base: API_PREFIX,
  // GET
  getGallery: `${API_PREFIX}/gallery`,
  getSpecs: `${API_PREFIX}/specifications/specs`,
  getSingleSpecs: `${API_PREFIX}/bp/specs`,
  getTrims: `${API_PREFIX}/bp/trims`,
  getFeatures: `${API_PREFIX}/features`,
  // getTrimInfo: `${API_PREFIX}/bp/TrimInfoConfigurator`,
  // getTrimInfo: `${API_PREFIX}/bp/TrimInfoConfiguratorGW`,
  // getTrimInfo: `${API_PREFIX}/bp/TrimInfoConfiguratorHL5`,
  getTrimInfo: `${API_PREFIX}/bp/TrimInfo`,
  getAccessory: `${API_PREFIX}/bp/accessory`,
  getTrimDetails: `${API_PREFIX}/trimDetails`,
  saveBuild: `${API_PREFIX}/bp/savebuild`,
  getBuildId: `${API_PREFIX}/bp/getbuildid`,
  getOffers: `${API_PREFIX}/offers`,
  getAccessories: `${API_PREFIX}/accessories`,
  retrieveBuild: `${API_PREFIX}/bp/retrieveBuild`,
  getModels: `${API_PREFIX}/raq/models`,
  getModelsBP: `${API_PREFIX}/bp/models`,
  getWallpapers: `${API_PREFIX}/downloads/wallpapers`,
  getRecalls: `${API_PREFIX}/recalls/getRecalls`,
  getModelHierarchy: `${API_PREFIX}/search-model-hierarchy`,
  searchInventory: `${API_PREFIX}/search-inventory`,
  getSearchModel: `${API_PREFIX}/search-model`,
  getSearchModelInventory: `${API_PREFIX}/search-model-inventory`,

  // POST
  sendQuote: `${API_PREFIX}/raq/sendQuote`,
  searchDealers: `${API_PREFIX}/raq/dealers`,
  dealerFinder: `${API_PREFIX}/dealers`,
  riderCoursesResults: `${API_PREFIX}/rider-courses-results`,
};
