import {SET_MODEL} from './action';
const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_MODEL: {
    return action.payload;
  }
  default: {
    return state;
  }
  }
}
