import types from './types';

const initialState = {
  value: {
    min: 0,
    max: 0,
  },
  isChanged: false,
};
export const priceRangeReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.PRICE_RANGE: {
    return {
      ...state,
      value: {
        ...action.payload,
      },
    };
  }
  case types.CHANGE_IS_CHANGED_PRICE_RANGE: {
    return {
      ...state,
      isChanged: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
