import * as types from './actions';

const initialState = {buildLoader: false, message: '', showSuccess: false, id: ''};

export default function(state = initialState, action) {
  switch (action.type) {
  case types.SAVE_LOADING: {
    return {
      buildLoader: true,
      message: '',
      showSuccess: false,
    };
  }
  case types.SAVE_COMPLETE: {
    return {
      buildLoader: false,
      message: action.payload.message,
      id: action.payload.result.id,
      showSuccess: true,
    };
  }
  case types.SAVE_ANIMATE: {
    return {
      buildLoader: 'animate',
      message: action.payload,
      showSuccess: false,
    };
  }
  case types.CLOSE_BUILD: {
    return {
      ...initialState,
      id: state.id,
    };
  }
  case types.UPDATE_BUILD_ID: {
    return {
      ...state,
      id: action.payload,
    };
  }
  default: {
    return state;
  }
  }
}
