export const SET_TERM = 'SET_TERM';
export const SET_FIELD = 'SET_FIELD';

export const setTerm = payload => ({
  type: SET_TERM,
  payload,
});

export const setField = payload => ({
  type: SET_FIELD,
  payload,
});
