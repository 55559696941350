import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {categories} from './categories/reducer';
import {calculate} from './calculate/reducer';
import {estimate} from './estimate/reducer';

export default combineReducers({
  categories,
  calculate,
  estimate,
  routing: routerReducer,
});
