import {setFooter} from '../setFooter/action';
export const SET_DEALERS = 'SET_DEALERS';

function addItem(state, item) {
  const newSet = [...state, item];
  return dispatch => {
    dispatch(setFooter(true));
    dispatch({
      type: SET_DEALERS,
      payload: newSet,
    });
  };
}

function removeItem(current, index) {
  const newSet = current.filter( (elem, key) => key !== index - 1);
  return dispatch => {
    if (newSet.length === 0) dispatch(setFooter(false));
    dispatch({
      type: SET_DEALERS,
      payload: newSet,
    });
  };
}

function resetItems() {
  return dispatch => {
    dispatch({
      type: SET_DEALERS,
      payload: [],
    });
  };
}

export function setDealers(dealer = false, active = false, index = 0, reset = false) {
  return (dispatch, getState) => {
    const current = getState().selectedDealers;
    if (active) return dispatch(removeItem(current, index));
    if (dealer) return dispatch(addItem(current, dealer));
    if (reset) return dispatch(resetItems());
  };
}
