import {SET_TRIM, SET_MODEL_STATE, SET_HIDE_ACCESSORIES_TAB, SET_USE_CONFIGURATOR} from './actions';
const HONDA = window.HONDA_BP ? window.HONDA_BP.GLOBAL_INFO : {};

const initialState = {
  ModelId: HONDA.trim ? HONDA.trim.ModelId : '',
  TotalPrice: HONDA.trim ? HONDA.trim.TotalPrice : '',
  ModelYear: HONDA.trim ? HONDA.trim.ModelYear : '',
  ModelName: HONDA.trim ? HONDA.trim.ModelName : '',
  TrimName: HONDA.trim ? HONDA.trim.TrimName : '',
  ModelState: null,
  HideAccessoriesTab: false,
  UseConfigurator: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_TRIM: {
    const ModelTextList = action.payload.ModelTextList || [];
    const trimName = ModelTextList.find(text => text.TextTypeCode === 'MCTRIM');

    return {
      ...state,
      ...action.payload,
      TrimName: trimName ? trimName.MarketingText : '',
    };
  }
  case SET_MODEL_STATE: {
    return {
      ...state,
      ModelState: action.payload,
    };
  }
  case SET_HIDE_ACCESSORIES_TAB: {
    return {
      ...state,
      HideAccessoriesTab: action.payload,
    };
  }
  case SET_USE_CONFIGURATOR: {
    return {
      ...state,
      UseConfigurator: action.payload,
    };
  }
  default: {
    return state;
  }
  }
}
