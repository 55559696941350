export const SET_TRIM = 'SET_TRIM';

export function setTrim(trim) {
  if (window.data_Layer && window.data_Layer.length > 0) {
    window.data_Layer[0].RAQ_modelYear = trim.ModelYear;
  }
  if (window.dataLayer && trim.ModelName) {
    window.dataLayer['Model.model_trim'] = trim.ModelName.toLowerCase();
    window.dataLayer['Model.model_year'] = trim.ModelYear;
    window.dataLayer['Model.model_id'] = trim.ModelId;
  }
  return {
    type: SET_TRIM,
    payload: trim,
  };
}
