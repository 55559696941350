import 'gsap/TweenLite';
import 'gsap/ScrollToPlugin';
import 'gsap/EasePack';
import 'gsap/CSSPlugin';

/**
 * Opens an element
 *
 * @example
 * import {open} from '../helpers';
 *
 * open($('.element'));
 *
 * @param {Object} el - HTML element you want to target for animation
 * @param {Number} speed - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */

export const open = (el, speed = 0.4, ease = 'easeOut', onComplete) => {
  TweenLite.set(el, {height: 'auto'});
  TweenLite.from(el, speed, {height: 0, ease: Expo[ease], onComplete});
};

/**
 * Closes an element
 *
 * @example
 * import {close} from '../helpers';
 *
 * close($('.element'));
 *
 * @param {Object} el - HTML element you want to target for animation
 * @param {Number} speed - Speed of the animation
 * @param {String} ease - Ease of the animation
 * @param {Function} onComplete - On complete function
 */
export const close = (el, speed = 0.4, ease = 'easeOut', onComplete) => {
  TweenLite.to(el, speed, {height: 0, ease: Expo[ease], onComplete});
};

/**
 * Gets url parameters
 *
 * @param {String} query - HTML element you want to target for animation
 *
 * @return {Object} - Returns an object containing url parameters.
 */
export const getUrlParams = query => {
  if (!query) return {};

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

/**
 * Renders React modules on a page
 *
 * @example
 * import {renderReactModule} from '../helpers';
 *
 * {name: 'bar/SomeComponent',
 *   render(component, el) {
 *     renderReactModule(component, el);
 *   }
 * }
 *
 * @param {Object} component - HTML element you want to target for animation
 * @param {Object} el - HTML element you want to target for animation
 * @param {Object} data - Additional data to be passed in to the React component
 */
export const renderReactModule = (component, el, data) => {
  const length = el.length;
  const React = require('react');
  const ReactDom = require('react-dom');

  for (let i = 0; i < length; i++) {
    ReactDom.render(React.createElement(component, data), el[i]);
  }
};

/**
 * Updates the browser history and pushes to new state
 *
 * @param {String} newQueryString - New url for the browser
 */
export const updateHistory = newQueryString => {
  const newURL = `${location.origin}${location.pathname}?${newQueryString}`;
  history.pushState({}, '', newURL);
};

/**
 * Scrolls to a certain part of the page with GSAP
 *
 * @param {Number} y - The new y position
 * @param {Number} speed - The spped in which the screen scrolls
 * @param {Function} onComplete - On complete function
 */
export const scrollTo = (y, speed = 1.5, onComplete) => {
  TweenLite.to(window, speed, {scrollTo: {y, autoKill: false}, ease: Expo.easeOut, onComplete});
};

/**
 * Creates and HTML string
 *
 * @param {Array} arr - Array of items
 * @param {Function} template - the template literal function
 * @return {String} - html string
 */
export const buildHtml = (arr, template) => {
  return arr.reduce((prev, curr, ind) => {
    return prev += template({...curr, index: ind});
  }, '');
};

/**
 * Formats a number to a price
 *
 * @param {Number} num - Any number
 * @return {String} - Formatted price with dollar sign prefixed
 */
export const formatPrice = (num = 0) => {
  const price = num.toString().split('.');
  price[0] = price[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (price[0] === '') {
    price[0] = '0';
  }

  if (price[1]) { // cut $9.999 to 9.99
    price[1] = price[1].substring(0, 2);
  } else { // add .00 for $9
    // price.push('00');
  }

  if (price[1] && price[1].length === 1) { // add a .0 for $9.9
    price[1] = `${price[1]}0`;
  }

  return `$${price.join('.')}`;
};

/**
 * Formats a north america phone number
 *
 * @param {Number} phone - Any 10 digit number
 * @return {String} - Formatted price with dollar sign prefixed
 */
export const formatPhone = (phone = 18001234567) => {
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
  } else if (phone.length === 11) {
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2)$3-$4');
  }

  return phone;
};

/**
 * Formats a north america address
 *
 * @param {Array} address - Strings in arrays
 * @return {String} - Formatted google maps prefixed
 */
export const formatDirections = (address = []) => {
  const googleFormat = 'https://www.google.com/maps/place/';
  const joined = googleFormat + address.map(a => {
    return a.split(' ').map(b => {
      return `${b}+`;
    });
  }).join();

  return joined;
};

/**
 * Used on previous and Next buttons, it is used to return an index for an array
 * checks for negative numbers and if negative will return the index for the last item in the array,
 * if key value is larger than the length it will return 0
 * if key is within the range it will return itself
 *
 * @param {Number} key - the desired index
 * @param {Number} length - the maximum length of the array
 * @return {Number} - returns an index for an array
 */

export const loopList = (key = 0, length = 0) => {
  if (Math.sign(key) === -1) return length - 1;
  if (key >= length) return 0;
  return key;
};

/**
 * Used to wrap setState with a promise so parent can use await
 *
 * @param {obj} rxComp - the react component on which we'll be calling setState
 * @param {obj} state - the state we want to update with
 */

export const setStateAsync = (rxComp, state) => {
  return new Promise(resolve => {
    rxComp.setState(state, resolve);
  });
};

/**
 * Checks LocalStorage for a stored zipcode
 *
*/

export const checkZip = async () => {
  if (window.dataLayer && window.dataLayer['Global.zipcode']) {
    return window.dataLayer['Global.zipcode'];
  }
  const {zip} = await $.getJSON(`/api/custom/getrequestdetails`);
  return zip || 90009;
};

/**
 * Saves Zip code to local storage
 * @param {string} zip - the new zip code to be saved
*/

export const saveZip = (zip = '') => {
  window.localStorage.setItem('zip', zip);
};

export const fixBodyScroll = scollPosition => {
  $('body').css({'top': `${-scollPosition}px`}).addClass('fixed');
};

export const clearBodyScroll = scollPosition => {
  $('body').removeClass('fixed').css({'top': ''});
  $(window).scrollTop(scollPosition);
};

/* eslint-disable no-multi-str */
export const msrpTemplate = '<div class="tooltip" role="tooltip">\
  <div class="tooltip-arrow"></div>\
  <button class="tooltip-close" aria-label="Close Tooltip"><svg role="img" aria-label="Close"><use xlink:href="#close" /></svg></button>\
  <div class="tooltip-inner"></div>\
</div>';

/* eslint-disable no-multi-str */
export const msrpTemplateOpenOnScroll = `
  <div class="tooltip" role="tooltip">
    <div class="tooltip-arrow"></div>
    <button
      class="tooltip-close js-open-on-scroll"
      aria-label="Close Tooltip"
    >
      <svg role="img" aria-label="Close"><use xlink:href="#close" /></svg>
    </button>
  <div class="tooltip-inner"></div>
  </div>
`;

export const multiImageDisclaimerTemplate = ({text, disclaimer, customClass}) => `
<span class="${customClass} image-disclaimer disclaimer__click-disable" tabindex="0" data-disclaimer="${disclaimer}">
    ${text}<svg class="asterisk-icon" viewBox="0 0 100 100" aria-label="disclaimer" role="img"><use xlink:href="#disclaimer-price"></use></svg>
  </span>
`;

export const disclaimerTemplate = ({text, disclaimer, customClass}) => {
  return `<span class="disclaimer ${customClass}" tabindex="0" data-disclaimer="${disclaimer}">
    ${text}<svg class="asterisk-icon" viewBox="0 0 100 100" aria-label="disclaimer" role="img"><use xlink:href="#disclaimer-price"></use></svg>
  </span>`;
};

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const dmtTrackWithDataLayer = ({event, type, label, category, custom = {}}) => {
  window.dataLayer = {...window.dataLayer};
  window.dataLayer['event_metadata.action_type'] = type;
  window.dataLayer['event_metadata.action_label'] = label;
  window.dataLayer['event_metadata.action_category'] = category;
  window.dataLayer = {
    ...window.dataLayer,
    ...custom,
  };
  return window._satellite.track(event);
};


export const dtmTrack = (eventString, pageViews = false, page = '') => {
  if (!window._satellite || !window.s) return;

  if (window.dataLayer && eventString === 'PageLoadReady' && page) {
    window.dataLayer['Page.sub_section2'] = page;
  }
  window._satellite?.track(eventString);
};

export const setAriaHiddenChildren = (parent, currentItem, state) => {
  $(parent).children().not(currentItem).attr('aria-hidden', state);
};

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export const parseHondaWord = str => {
  const regEx = new RegExp('honda', 'ig');
  return str.replace(regEx, 'Honda');
};

export const setBPpageTitle = ({step, stepCount, year, model}) => (
  document.title = `${step}, ${year} ${model}, Build Step ${stepCount} of 4 | Honda`
);

export const capitalizeFirstLetter = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const BANNER_KEY = 'header-banner';

export const saveCloseBannerState = () => {
  window.sessionStorage.setItem(BANNER_KEY, true);
};

export const shouldAllowNewBanner = () => {
  const {type, TYPE_RELOAD} = window.performance.navigation;
  const isInternalNavigation = document.referrer.startsWith(window.location.origin);
  const isRefresh = type === TYPE_RELOAD;

  if (isRefresh || !isInternalNavigation) {
    window.sessionStorage.clear(BANNER_KEY);
    return true;
  }

  const storedKey = window.sessionStorage.getItem(BANNER_KEY);
  return Boolean(!storedKey);
};

export function check360galleryButtonNeed(arg, previousActiveIndex) {
  const view360Button = $('.gallery-item-trigger[data-index="1"]');
  const interior360Button = $('.gallery-item-trigger[data-index="2"]');

  setTimeout(() => {
    if (!arg.imgPath) {
      view360Button.closest('.gallery-header-item').css('display', 'none');
    } else {
      view360Button.closest('.gallery-header-item').css('display', 'inline-block');
    }

    if (!arg.rootPathInterior360) {
      interior360Button.closest('.gallery-header-item').css('display', 'none');
    } else {
      interior360Button.closest('.gallery-header-item').css('display', 'inline-block');
    }
  }, 400);
}
