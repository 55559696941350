export const SET_MODEL_PAGE = 'SET_MODEL_PAGE';

export function setModelPage(key) {
  if (window.dataLayer && key) {
    window.dataLayer['Model.model_trim'] = '';
    window.dataLayer['Model.model_year'] = '';
    window.dataLayer['Model.model_id'] = '';
  }
  return {
    type: SET_MODEL_PAGE,
    payload: key,
  };
}
