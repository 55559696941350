import types from './types';

const initialState = {
  modelTrimsAvailable: [],
  modelTrimSelected: '',
  colorsAvailable: [],
  colorSelected: '',
  modelSelected: null,
  contactType: '',
  localDealerSelected: '',
  mainDealer: null,
  mainDealerInventory: null,
  otherDealersAvailable: null,
  dealersSelected: [],
  isSubmitting: false,
  submitSuccess: null,
  submitError: null,
  dealersInventoryStatus: {},
  dealerMainVinStatus: 'DEFAULT',
};

export const updateContactScreenDataReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_CONTACT_COLOR_SELECTED: {
    return {
      ...state,
      colorSelected: action.payload,
    };
  }
  case types.CHANGE_CONTACT_TRIM_SELECTED: {
    return {
      ...state,
      modelTrimSelected: action.payload,
    };
  }
  case types.CHANGE_CONTACT_MODEL_SELECTED: {
    return {
      ...state,
      modelSelected: {...action.payload},
    };
  }
  case types.CHANGE_CONTACT_TYPE: {
    return {
      ...state,
      contactType: action.payload,
    };
  }
  case types.CHANGE_CONTACT_LOCAL_DEALER_SELECTED: {
    return {
      ...state,
      localDealerSelected: action.payload,
    };
  }
  case types.CHANGE_CONTACT_MAIN_DEALER: {
    return {
      ...state,
      mainDealer: {...action.payload},
    };
  }
  case types.CHANGE_CONTACT_OTHER_DEALERS_AVAILABLE: {
    return {
      ...state,
      otherDealersAvailable: [...action.payload],
    };
  }
  case types.CHANGE_CONTACT_DEALERS_SELECTED: {
    if (state.dealersSelected.includes(action.payload)) {
      return {...state, dealersSelected: state.dealersSelected.filter(dealer => dealer !== action.payload)};
    }
    return {...state, dealersSelected: [...state.dealersSelected, action.payload]};
  }
  case types.CHANGE_CONTACT_TRIMS_AVAILABLE: {
    return {
      ...state,
      modelTrimsAvailable: [...action.payload],
    };
  }
  case types.CHANGE_CONTACT_COLORS_AVAILABLE: {
    return {
      ...state,
      colorsAvailable: [...action.payload],
    };
  }
  case types.RESET_CONTACT_STATE: {
    return {
      ...initialState,
    };
  }
  case types.SUBMIT_CONTACT_PENDING: {
    return {
      ...state,
      isSubmitting: true,
      submitSuccess: null,
      submitError: null,
    };
  }
  case types.SUBMIT_CONTACT_FULFILLED: {
    return {
      ...state,
      isSubmitting: false,
      submitSuccess: action.payload,
      submitError: null,
    };
  }
  case types.SUBMIT_CONTACT_REJECTED: {
    return {
      ...state,
      isSubmitting: false,
      submitSuccess: null,
      submitError: action.payload,
    };
  }
  case types.CHANGE_DEALERS_INVENTORY_STATUS: {
    return {
      ...state,
      dealersInventoryStatus: {
        ...state.dealersInventoryStatus,
        [action.payload.dealerId]: action.payload.inventoryStatus,
      },
    };
  }
  case types.CHANGE_DEALER_MAIN_VIN_STATUS: {
    return {
      ...state,
      dealerMainVinStatus: action.payload,
    };
  }
  default:
    return state;
  }
};
